import styles from "./NewPassword.module.css";
import logo from "../images/logo.png";
import {Link, useParams} from "react-router-dom";
import {CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import {MdLock} from "react-icons/md";
import {useState} from "react";

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
}
const userPool = new CognitoUserPool(poolData);

const NewPasswordPage = () => {
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const userData = {
        Username: params.username,
        Pool: userPool
    }

    const changePasswordHandler = (e) => {
        setPassword(e.target.value);
    }

    const changePassword = (e) => {
        setLoading(true);
        e.preventDefault();
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(params.code, password, {
            onSuccess: (data) => {
                console.log(data);
                setSuccess(true);
                setMessage("Se ha cambiado tu clave satisfactoriamente. Para continuar haz click en el botón de abajo.")
                setLoading(false);
            },
            onFailure: (err) => {
                console.log(err);
                setErrorMessage("Hubo un error al cambiar la clave.")
                setLoading(false);
            }
        })
    }

    return <div className={styles.container}>
        {!success && <div className={styles.form}>
            <img src={logo} alt="Hero" className={styles.logo}/>
            <h1 className={styles.title}>Cambio de clave</h1>
            <form onSubmit={changePassword}>
                <div className={styles.email}>
                    <MdLock className={styles.icon}/>
                    <input type="password" placeholder="**********" onChange={changePasswordHandler} required/>
                </div>
                <button className={styles.button} type='submit'>Cambiar clave</button>
            </form>
            {loading && <progress value={null} className={styles.progress} />}
            <div className={styles.error}>{errorMessage}</div>
        </div>}
        {success && <div className={styles.form}>
            <img src={logo} alt="Hero" className={styles.logo} />
            <h1 className={styles.title}>Cambio de clave</h1>
            <p className={styles.message}>{message}</p>
            <Link to="/auth/login" className={styles.button}>Ingresar</Link>
        </div>}
    </div>
}

export default NewPasswordPage;