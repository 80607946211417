import styles from "./ForgotPassword.module.css";
import logo from "../images/logo.png";
import {MdEmail} from "react-icons/md";
import {Link} from "react-router-dom";
import {CognitoUserPool, CognitoUser} from 'amazon-cognito-identity-js';
import {useState} from "react";

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
}

const userPool = new CognitoUserPool(poolData);

function extractUserName(email) {
    const indexAt = email.indexOf("@");
    return email.substring(0, indexAt);
}

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const cognitoUser = new CognitoUser({
        Username: extractUserName(email),
        Pool: userPool
    });



    const recoverPassword = (e) => {
        e.preventDefault();
        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                console.log(data);
                setSuccess(true)
                setLoading(false);
            },
            onFailure: (err) => {
                console.error(err);
                setErrorMessage(JSON.stringify(err));
                setLoading(false);
            }
        })
    }

    const changeEmailHandler = (e) => {
        setEmail(e.target.value);
    }

    return <div className={styles.container}>
        <div className={styles.form}>
            <img src={logo} alt="Hero" className={styles.logo}/>
            <h1 className={styles.title}>Olvidé mi clave</h1>
            {!success && <form onSubmit={recoverPassword}>
                <div className={styles.email}>
                    <MdEmail className={styles.icon}/>
                    <input
                        type="email"
                        placeholder="usuario@correo.com"
                        autoFocus
                        onChange={changeEmailHandler}
                        required
                    />
                </div>
                <button className={styles.button} type='submit'>Enviar</button>
                <div className={styles.options}>
                    <Link to="/auth/login" className={styles.link}>Ya recordé mi clave</Link>
                </div>
                {loading && <progress value={null} className={styles.progress} />}
                <p className={styles.error}>{errorMessage}</p>
            </form>}
            {success && <p className={styles.message}>
                Revisa tu bandeja de correo para instrucciones de como cambiar tu clave.
            </p>}
        </div>
    </div>
}

export default ForgotPasswordPage