import styles from './Card.module.css';

const CardComponent = props => {
    return <div className={styles.card}>
        <div className={styles['card-title']}>
            {props.title}
        </div>
        <div className={styles['card-body']}>
            {props.children}
        </div>
    </div>
}

export default CardComponent;