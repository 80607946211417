import styles from './ConfirmByCode.module.css'
import logo from "../images/logo.png";
import {Link, useParams} from "react-router-dom";
import {useState, useRef} from "react";

const ConfirmByCodePage = () => {
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    const [text4, setText4] = useState("");
    const [text5, setText5] = useState("");
    const [text6, setText6] = useState("");

    const input1 = useRef();
    const input2 = useRef();
    const input3 = useRef();
    const input4 = useRef();
    const input5 = useRef();
    const input6 = useRef();

    const link1 = useRef();
    const params = useParams();

    return <div className={styles.container}>
        <div className={styles.card}>
            <img src={logo} alt="Hero" className={styles.logo}/>
            <div className={styles.boxes}>
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input1}
                    value={text1}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText1(val);
                        if (val.length >= 1) {
                            input2.current.focus();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text1) {
                            // Stay on the first input
                        }
                    }}
                />
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input2}
                    value={text2}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText2(val);
                        if (val.length >= 1) {
                            input3.current.focus();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text2) {
                            input1.current.focus();
                        }
                    }}
                />
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input3}
                    value={text3}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText3(val);
                        if (val.length >= 1) {
                            input4.current.focus();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text3) {
                            input2.current.focus();
                        }
                    }}
                />
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input4}
                    value={text4}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText4(val);
                        if (val.length >= 1) {
                            input5.current.focus();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text4) {
                            input3.current.focus();
                        }
                    }}
                />
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input5}
                    value={text5}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText5(val);
                        if (val.length >= 1) {
                            input6.current.focus();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text5) {
                            input4.current.focus();
                        }
                    }}
                />
                <input
                    className={styles.box}
                    type="text"
                    maxLength={1}
                    ref={input6}
                    value={text6}
                    onChange={(e) => {
                        const val = e.target.value;
                        setText6(val);
                        // Optionally, you can trigger form submission here
                        link1.current.focus();

                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !text6) {
                            input5.current.focus();
                        }
                    }}
                />
            </div>
            <p className={styles.message}>Ingresa el código de confirmación que hemos enviado a tu correo.</p>
            <Link ref={link1} to={`/auth/confirm/${params.username}/${text1}${text2}${text3}${text4}${text5}${text6}`}
                  className={styles.button}>Enviar</Link>
        </div>
    </div>
}

export default ConfirmByCodePage;