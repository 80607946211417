import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";
import ConfirmPage from "./pages/Confirm";
import ConfirmByCodePage from "./pages/ConfirmByCode";
import ForgotPasswordPage from "./pages/ForgotPassword";
import NewPasswordPage from "./pages/NewPassword";
import DashboardPage from "./pages/Dashboard";
import RootLayoutPage from "./pages/RootLayout";

const router = createBrowserRouter([
    { path: "/auth/register", element: <RegisterPage /> },
    { path: "/auth/login", element: <LoginPage /> },
    { path: "/auth/confirm/:username/:code", element: <ConfirmPage />},
    { path: "/auth/confirmByCode/:username", element: <ConfirmByCodePage /> },
    { path: "/auth/forgotPassword", element: <ForgotPasswordPage /> },
    { path: "/auth/newPassword/:username/:code", element: <NewPasswordPage /> },
    { path: "/", element: <HomePage /> },
    { path: "/dashboard", element: <RootLayoutPage />, children: [
            { path: "", element: <DashboardPage /> },
        ] }
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
